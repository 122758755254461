.display-list {
    opacity: 1;
    will-change: opacity;
}

.flash {
    animation-name: flash-anim;
    animation-duration: 1000ms;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
}

@keyframes flash-anim {
    from {opacity: 0;}
    25% {opacity: 0;}
    to {opacity: 1;}
}

.more-results {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
}

.more-results button {
    margin-top: 16px;
    background-color: white;
    border: 2px solid pink;
    padding: 8px;
    font-family: 'zona_probold', 'Roboto', sans-serif;
    font-weight: bold;
}

.shop-address {
    margin-top: 24px;
    padding-bottom: 160px;
}

a {
    color: rgb(180, 104, 115);
    text-decoration: none;
}