html {
    scroll-behavior: smooth;
    cursor: default;
}

.app {
    text-align: center;
}

h1 {
    font-size: 30px;
    font-family: 'abraham_lincolnregular';
    text-transform: uppercase;
    letter-spacing: 7px;
    line-height: 45px;
    margin-top: 30px;
    margin-bottom: 0;
    font-weight: normal;
}

.intro {
    margin-top: 8px;
    padding: 16px;
}

h2 {
    font-size: 28px;
    font-family: 'abraham_lincolnregular';
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-top: 30px;
    font-weight: normal;
}

.display-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #dbcecb;
}