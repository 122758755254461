.dropdown {
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.6);;
}

.dropdown .left {
    background-color: rgba(250, 194, 203, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.dropdown .right {
    background-color: rgba(250, 194, 203, 0.2);
    padding: 8px;
}

.serial {
    width: 24px;
    font-size: 125%;
    padding-left: 28px;
    opacity: 0.33;
}

.dropdown.macaron-part-1 .left {
    border-bottom-left-radius: 12px;
}

.dropdown.macaron-part-2 .left {
    margin-left: 18px;
}

.dropdown.macaron-part-2 .serial {
    width: 22px;
    padding-left: 12px;
}

.dropdown.macaron-part-3 .left {
    border-top-left-radius: 12px;
}

.question {
    font-weight: bold;
}

select {
    padding: 8px;
    width: 240px;
    margin-top: 8px;
    font-family: 'zona_probold', 'Roboto', sans-serif;
    border: 2px solid pink;
    background-color: white;
    border-radius: 0;
    font-size: 14px;
    padding: 10px;
}

/* iOS Safari enhancement */

select, option {
    -webkit-appearance: none;
}

select {
    padding-right: 24px;
}

.select-wrapper {
    position: relative;
    display: inline-block;
  }

.select-wrapper:after {
    content: '\00142F';
    /* font: normal normal normal 12px/1 FontAwesome; */
    color: rgb(231, 133, 149);
    right: 14px;
    top: 4px;
    height: 26px;
    padding: 15px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
}
