#language-selector {
    float: right;
    height: 0;
    overflow: visible;
    margin-right: 10vw;
}

#language-selector button {
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    font-family: "zona_probold";
    font-weight: bold;
    padding: 4px;
}

#language-selector .active {
    color: pink;
}