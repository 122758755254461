.macaron-display {
    flex: 0 0 auto;
    width: 45vw;
    height: 45vw;
    max-width: 240px;
    max-height: 240px;
    min-width: 170px;
    min-height: 170px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    cursor: pointer;
}

.macaron-display .hover {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    z-index: 5;
    transition: all 0.25s;
}

.macaron-display:hover .hover {
    opacity: 1;
}

.macaron-display .frame {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: url(https://www.chezdodo.hu/images/macaronmaszk.png);
    background-size: cover;
    z-index: 15;
}

.macaron-display .details {
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 25;
    margin: 14px 15px 15px 14px;
    border: 1px solid white;
    text-align: center;
}

.macaron-display .text {
    position: absolute;
    bottom: 25px;
    left: 0px;
    width: 100%;
}

.macaron-display .name {
    font-family: 'abraham_lincolnregular';
    color: white;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 2px;
    width: 100%;
    text-align: center;
}

.macaron-display .flavor {
    font-family: "zona_probold";
    color: white;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 400;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    line-height: 18px;
}

.macaron-display a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba(0,0,0,0.0);
    z-index: 35;
    cursor: pointer !important;
    display: block;
}