/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 26, 2017 */



@font-face {
    font-family: 'abraham_lincolnregular';
    src: url('abrahamlincoln-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'zona_probold';
    src: url('zonapro-bold-webfont.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;

}


@font-face {
    font-family: 'zona_probold';
    src: url('zonapro-regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'zona_probold';
    src: url('zonapro-extralight-webfont.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;

}